import * as React from 'react'
import * as styles from '../components/credit.module.scss'
import GlobalWrapper from '../components/GlobalWrapper'
import SEO from '../components/SEO'
import YanagiSign from '../images/yanagi_sign.png'

const IndexPage = () => {
  return (
    <>
      <SEO title="CREDIT" />
      <main className={styles.container}>
        <GlobalWrapper>
          <div className={styles.creditWrapper}>
            <div className={styles.section}>
              <h1 className={styles.title}>研究室ロゴデザイン</h1>
              <p className={styles.studentName}>柳　英克</p>
            </div>
            <div className={styles.section}>
              <h1 className={styles.title}>情報提供協力</h1>
              <table className={styles.studentTable}>
                <tbody>
                  <tr>
                    <th className={styles.studentTableHead}>担当教員</th>
                    <td className={styles.studentTableName}>柳　英克</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>１期生</th>
                    <td className={styles.studentTableName}>佐々木　麻美</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>３期生</th>
                    <td className={styles.studentTableName}>安本　匡佑</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>５期生</th>
                    <td className={styles.studentTableName}>上村　友美</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>６期生</th>
                    <td className={styles.studentTableName}>小玉　美菜</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>８期生</th>
                    <td className={styles.studentTableName}>土谷　幹</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead} rowSpan="2">
                      １０期生
                    </th>
                    <td className={styles.studentTableName}>宇野　冬樹</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>浜出　絵梨</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>１２期生</th>
                    <td className={styles.studentTableName}>加藤　悠一</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead} rowSpan="3">
                      １４期生
                    </th>
                    <td className={styles.studentTableName}>加納　俊平</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>小田島　慧</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>川島　奨大</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead} rowSpan="2">
                      １５期生
                    </th>
                    <td className={styles.studentTableName}>佐藤　樹史</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>今野　彩音</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead}>１６期生</th>
                    <td className={styles.studentTableName}>郡司　俊美</td>
                  </tr>
                  <tr>
                    <th className={styles.studentTableHead} rowSpan="5">
                      １８期生
                    </th>
                    <td className={styles.studentTableName}>畠　椋</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>北山　奏人</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>神代　大弥</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>後藤　隆太</td>
                  </tr>
                  <tr>
                    <td className={styles.studentTableName}>山﨑　陽聖</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.section}>
              <h1 className={styles.title}>制作</h1>
              <p className={styles.studentName}>YANAGI's LAB</p>
            </div>
            <p className={styles.professorName}>柳　英克</p>
            <div>
              <img
                className={styles.signImage}
                src={YanagiSign}
                alt="柳研究室のロゴマーク"
              />
            </div>
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export default IndexPage
