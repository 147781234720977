// extracted by mini-css-extract-plugin
export var container = "credit-module--container--1acbj";
export var creditWrapper = "credit-module--creditWrapper--1EHRd";
export var section = "credit-module--section--wQjr2";
export var title = "credit-module--title--2duGZ";
export var studentName = "credit-module--studentName--2a2WB";
export var studentTable = "credit-module--studentTable--3CZQN";
export var studentTableHead = "credit-module--studentTableHead--Rr9FL";
export var studentTableName = "credit-module--studentTableName--AnW75";
export var professorName = "credit-module--professorName--3KRLr";
export var signImage = "credit-module--signImage--3FDKT";